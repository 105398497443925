import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { $auth } from 'stores';
import { authorized } from 'htcore';

const Header = observer(() => {
    const { pathname } = useLocation();

    const [open, setOpen] = useState(false);

    const isAuthorized = authorized();

    const onClickMenu = () => {
        document.body.style.overflowY = !open ? 'hidden' : 'auto';
        setOpen((prevState) => !prevState);
    };

    const linksGroup = (
        <>
            <div className="link-wrapper" data-active-link={pathname.includes('product')}>
                <span className="icon icon-plane" />
                <Link to="/product" className="dark-link">
                    Our Product
                </Link>
            </div>
            <div className="link-wrapper" data-active-link={pathname.includes('about-us')}>
                <span className="icon icon-plane" />
                <Link to="/about-us" className="dark-link">
                    About us
                </Link>
            </div>
            <div className="link-wrapper" data-active-link={pathname.includes('blog')}>
                <span className="icon icon-plane" />
                <Link to="/blog" className="dark-link">
                    Blog
                </Link>
            </div>
        </>
    );

    const agentSettingsLink = (
        <Link to="/settings/agent" className="agent-link">
            <span className="icon icon-burger-menu" />
            <span className="icon icon-avatar" />
        </Link>
    );

    const buttonsGroup = !isAuthorized && (
        <>
            <Link to="/signup">
                <button className="lg-button button-primary">Join Us</button>
            </Link>
            <Link to="/login">
                <button className="lg-button button-secondary">Sign In</button>
            </Link>
        </>
    );

    const initialisedButtonsGroup = (
        <div className="initialized-buttons">
            <div className="hide-laptop">
                <div className="link-wrapper" data-active-link={pathname.includes('product')}>
                    <span className="icon icon-plane" />
                    <Link to="/search" className="dark-link">
                        Search
                    </Link>
                </div>
            </div>

            {$auth.permitted('ViewAgencyBookings') && (
                <div className="link-wrapper" data-active-link={pathname.includes('product')}>
                    <span className="icon icon-plane" />
                    <Link to="/bookings" className="dark-link">
                        Bookings
                    </Link>
                </div>
            )}

            {agentSettingsLink}
        </div>
    );

    return (
        <section className="section-wrapper">
            <header className="lg-header">
                <div className={open ? 'mobile-menu mobile-menu-open' : 'mobile-menu'}>
                    <div className="mobile-links hide-laptop-desktop">
                        <div className="hide-laptop-desktop link-wrapper ">
                            <span className="icon icon-plane" />
                            <Link to="/" className="dark-link">
                                Home
                            </Link>
                        </div>

                        {isAuthorized && (
                            <>
                                <div className="hide-laptop-desktop link-wrapper ">
                                    <span className="icon icon-plane" />
                                    <Link to="/bookings" className="dark-link">
                                        Bookings
                                    </Link>
                                </div>

                                <div className="hide-laptop-desktop link-wrapper ">
                                    <span className="icon icon-plane" />
                                    <Link to="/search" className="dark-link">
                                        Search
                                    </Link>
                                </div>
                            </>
                        )}

                        {linksGroup}
                    </div>
                    <div className="mobile-buttons-group hide-laptop-desktop">{buttonsGroup}</div>
                </div>
                <nav>
                    {open ? (
                        <div className="icon icon-cross hide-laptop-desktop" onClick={onClickMenu} />
                    ) : (
                        <div className="icon icon-burger-menu hide-laptop-desktop" onClick={onClickMenu} />
                    )}
                    <div className="links-group">{linksGroup}</div>

                    <Link to="/">
                        <img className="logo" src="/images/landing/logo.png" alt="Happytravel.com" />
                    </Link>

                    {isAuthorized && <div className="join-us-link">{agentSettingsLink}</div>}

                    {open ? (
                        <div className="buttons-group">{agentSettingsLink}</div>
                    ) : (
                        <>
                            <div className="buttons-group">
                                {isAuthorized ? initialisedButtonsGroup : buttonsGroup}
                            </div>
                            {!isAuthorized && (
                                <Link to="/login" className="dark-link join-us-link underline">
                                    Sign In
                                </Link>
                            )}
                        </>
                    )}
                </nav>
            </header>
        </section>
    );
});

export default Header;
