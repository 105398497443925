import React from 'react';
import { Link } from 'react-router-dom';
import Swiper from '../../components/swiper';
import { authorized } from 'htcore';

const SLIDE_WIDTH = 400;

const content = [
    {
        title: 'In-house travel technology',
        text: 'Happy Travel’s technology is custom-designed by our global IT experts, ensuring rapid, modern, and efficient booking experiences.',
    },
    {
        title: 'Global reach, local insight with on-site support',
        text: 'Happy Travel offers worldwide support, assuring immediate local assistance and strong hotel partnerships, always a call away.',
    },
    {
        title: 'Seamless integration suite',
        text: 'Happy Travel’s Integration Suite streamlines bookings, syncing effortlessly with your systems for smooth, efficient operations.',
    },
];

const PlatformBlock = () => {
    const isAuthorized = authorized();

    return (
        <section id="platform" className="section-wrapper block-paddings platform-block">
            <div className="block-heading">
                <h2>
                    We are more than just a <span>booking site</span>
                </h2>
                <p>Even with new tech, our biggest strength is our people's know-how</p>
            </div>

            <Swiper className="swiper-cards" slideWidth={SLIDE_WIDTH}>
                {content.map(({ title, text }, index) => (
                    <React.Fragment key={index}>
                        <div draggable={false} className="card slide">
                            <p className="card-heading">{title}</p>
                            <p className="text">{text}</p>
                        </div>

                        {index === 1 && (
                            <div draggable={false} className="discover-card moved-arrow-wrapper">
                                <div className="circle-arrow">
                                    <span className="icon icon-arrow" />
                                </div>
                                <Link to={isAuthorized ? '/search' : '/signup'} className="heading">
                                    Discover how it works
                                </Link>
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </Swiper>
        </section>
    );
};

export default PlatformBlock;
