import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { authorized } from 'htcore';

const content = [
    {
        title: (
            <h2>
                <span>Global concierge</span> services
            </h2>
        ),
        subTitle: 'Global concierge',
        text: "Experience unparalleled convenience with our Global Concierge Services, offering personalized assistance across three continents. Whether it's securing restaurant reservations, arranging exclusive access to events, or facilitating last-minute requests, our concierge team ensures a premium experience tailored to your discerning needs, around the globe.",
    },
    {
        title: (
            <h2>
                <span>Exceptional transportation</span> services
            </h2>
        ),
        subTitle: 'Exceptional transportation',
        text: 'Traverse with confidence in the UAE, the UK, Turkey, and France with our Exceptional Transportation Services. Dedicated to your comfort and timely arrival, we provide a fleet of luxurious vehicles and experienced chauffeurs to ensure smooth and stylish transit. Whether for individual or group journeys, our service is synonymous with reliability and class.',
    },
    {
        title: (
            <h2>
                <span>Personalized</span> travel itinerary planning
            </h2>
        ),
        subTitle: 'Personalized travel itinerary planning',
        text: "Elevate your clients' travel experience with our bespoke itinerary planning service. Our team of travel experts crafts tailor-made schedules that cater to individual tastes and preferences, ensuring a seamless journey. From cultural excursions and culinary tours to adventure activities, we design enriching itineraries that create unforgettable memories in destinations worldwide.",
    },
];

const ServicesBlock = () => {
    const [activeBlock, setActiveBlock] = useState(0);
    const [stoppedInterval, setStoppedInterval] = useState(false);

    const isAuthorized = authorized();

    const interval = useRef();

    const onBlockClick = (index) => {
        clearInterval(interval.current);
        setStoppedInterval(true);
        setActiveBlock(index);
    };

    useEffect(() => {
        interval.current = setInterval(() => {
            setActiveBlock((currentBlock) => {
                const nextBlock = currentBlock + 1;
                return nextBlock > 2 ? 0 : nextBlock;
            });
        }, 10000);

        return () => clearInterval(interval.current);
    }, []);

    return (
        <section className="section-wrapper services-block">
            {content.map(({ title, text, subTitle }, index) => (
                <div
                    key={index}
                    data-active={activeBlock === index}
                    data-stopped-interval={stoppedInterval}
                    className="card"
                    onClick={() => onBlockClick(index)}
                >
                    <div className="closed-card">
                        <div className="icon-wrapper">
                            <span className="icon icon-minus" />
                            <span className="icon icon-minus icon-plus" />
                        </div>
                        <p className="subtitle">{subTitle}</p>
                    </div>

                    <div className="hidden-block">
                        {title}
                        <p className="text">{text}</p>
                        {isAuthorized ? (
                            <a href="mailto:reservations@happytravel.com">
                                <button className="lg-button button-secondary">Request contact</button>
                            </a>
                        ) : (
                            <Link to="/signup">
                                <button className="lg-button button-secondary">Request contact</button>
                            </Link>
                        )}
                    </div>
                    <div className="image-wrapper">
                        <div className="image">
                            <img
                                draggable="false"
                                className="phone-img"
                                src="/images/landing/home/phone.png"
                                alt="Mobile Application"
                            />
                        </div>
                    </div>
                </div>
            ))}
        </section>
    );
};

export default ServicesBlock;
