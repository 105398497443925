import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { SEARCH_STATUSES } from 'enum';
import {
    searchLoadPage,
    searchLoadWithNewFilters,
    searchLoadWithNewOrder,
    PAGE_SIZE,
} from 'tasks/accommodation/search-loaders';
import { searchRoomsCreate } from 'tasks/accommodation/search-rooms-loaders';
import { useTitle } from 'htcore';
import { date, PassengersCount } from 'simple';
import { Loader } from 'components/simple';
import SearchFilters from 'pages/accommodation/search-filters/search-filters';
import SearchNothingFound from './search-nothing-found';
import AccommodationTitlePage from '../title';
import AccommodationSearchResultContract from './search-result-contract';
import SearchBigLoader from './search-big-loader';
import ItineraryNotification from '../itinerary-notification';
import { $accommodation, $ui } from 'stores';
import Pager from './pager';
import AccommodationFiltersForm from '../search-filters/accommodation-filters-form';
import {
    useSearchIntervalUpdater,
    useSearchSocketConnection,
} from '../../../tasks/accommodation/search-loader-hooks';

const AccommodationSearchResultsPage = observer(() => {
    useTitle('Search Results');
    const [filtersLastUpdated, setFiltersLastUpdated] = useState(0);
    const navigate = useNavigate();

    useSearchIntervalUpdater();
    useSearchSocketConnection();

    const clearFilters = () => {
        searchLoadWithNewFilters(null);
        setFiltersLastUpdated(Number(new Date()));
    };

    const { search, hotelArray, hasAnyFilters } = $accommodation;
    const { taskState, request } = search;

    if (
        !request?.destination ||
        !search.taskState ||
        (!search.id && SEARCH_STATUSES.BROKEN === search.taskState)
    )
        return <AccommodationTitlePage noSearch />;

    document.title = request.destination + ' – ' + 'Happytravel.com';

    if (search.result.length === 1 && request?.htIds?.[0].includes('Accommodation_')) {
        searchRoomsCreate(search.result[0], navigate);
        return <SearchBigLoader key={search.createdAt} destination={search.request.destination} />;
    }

    if (
        taskState !== SEARCH_STATUSES.BROKEN &&
        !search.resultCount &&
        (!SEARCH_STATUSES.isReadyToLoad(taskState) ||
            (!search.result.length && SEARCH_STATUSES.PARTIALLY_COMPLETED === taskState))
    )
        return (
            <SearchBigLoader
                key={search.createdAt}
                destination={search.request.destination}
                customTitle={request?.htIds?.[0].includes('Accommodation_') && 'Searching for rooms'}
            />
        );

    const pageCount = Math.ceil((search.availabilityCount || search.resultCount) / PAGE_SIZE);
    const SearchPaging = hotelArray.length ? (
        <Pager pageCount={pageCount} currentPage={search.page} action={searchLoadPage} />
    ) : null;

    const Nights = __plural(search.numberOfNights, 'Night');

    const Guests = (
        <PassengersCount
            adults={request.roomDetails.reduce((res, item) => res + item.adultsNumber, 0)}
            children={request.roomDetails.reduce((res, item) => res + item.childrenNumber, 0)}
        />
    );

    return (
        <div className={'search-results first-step block' + ($ui.itnMode ? ' with-itn' : '')}>
            <div className="notification-wrapper">
                <ItineraryNotification />
            </div>
            <div className="heading-section">
                <section>
                    {search.loading && <Loader page />}
                    <div className="head">
                        <div className="request">
                            <span>
                                {date.format.day(date.day(request.checkInDate))} –{' '}
                                {date.format.day(date.day(request.checkOutDate))}
                            </span>{' '}
                            • <span>{Nights}</span> • <span>{Guests}</span>
                        </div>

                        <div className="title">
                            <h2>
                                <div>
                                    {!request.htIds?.[0]?.includes('Accommodation_') &&
                                        'Accommodations in' + ' '}
                                    {request.destination}{' '}
                                </div>
                                {!!search.resultCount && (
                                    <span>
                                        (
                                        {search.availabilityCount !== undefined
                                            ? search.availabilityCount
                                            : search.resultCount}
                                        {!SEARCH_STATUSES.isFinished(taskState) && '+'})
                                    </span>
                                )}
                            </h2>
                        </div>
                    </div>

                    <div className="upper-part">
                        <div className="left">
                            {!!search.result.length || hasAnyFilters ? (
                                <SearchFilters
                                    key={filtersLastUpdated}
                                    update={setFiltersLastUpdated}
                                    filterAction={searchLoadWithNewFilters}
                                    sorterAction={searchLoadWithNewOrder}
                                />
                            ) : null}
                        </div>
                        <div className="right">{SearchPaging}</div>
                    </div>
                </section>
            </div>
            <section className="two-column-wrapper">
                <div className="column left">
                    {!!search.result.length || hasAnyFilters ? (
                        <AccommodationFiltersForm
                            key={filtersLastUpdated}
                            filterAction={searchLoadWithNewFilters}
                        />
                    ) : null}
                </div>

                <div className="column right">
                    {!hotelArray.length && !search.loading ? (
                        <SearchNothingFound
                            resultCount={search.resultCount}
                            filters={hasAnyFilters}
                            clearFilters={clearFilters}
                        />
                    ) : (
                        <div className="results">
                            {hotelArray.map((item, index) => (
                                <AccommodationSearchResultContract
                                    key={`${item.htId}-${index}`}
                                    contract={item}
                                    setFiltersLastUpdated={setFiltersLastUpdated}
                                />
                            ))}

                            {hotelArray.length < PAGE_SIZE &&
                            !SEARCH_STATUSES.isFinished(search.taskState) ? (
                                <Loader />
                            ) : null}

                            {pageCount - 1 === search.page &&
                                pageCount &&
                                SEARCH_STATUSES.isFinished(search.taskState) && (
                                    <div className="finish">
                                        {search.availabilityCount} found
                                        {search.availabilityCount &&
                                        search.availabilityCount !== search.resultCount
                                            ? ` of ${search.resultCount} total`
                                            : ''}
                                    </div>
                                )}

                            {SearchPaging}
                        </div>
                    )}
                </div>
            </section>
        </div>
    );
});

export default AccommodationSearchResultsPage;
