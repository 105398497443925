import React from 'react';
import { Link } from 'react-router-dom';
import { authorized } from 'htcore';
import Swiper from '../../components/swiper';
import content from '../../content/open-positions.json';

const SLIDE_WIDTH = 400;

const CustomArrows = ({ hideRightArrow, hideLeftArrow, scrollToLeft, scrollToRight }) => (
    <div className="block-heading">
        <h2>
            Open <span>positions</span>
        </h2>
        <div className="arrows-wrapper">
            <div data-disabled={hideLeftArrow} className="circle-arrow prev-arrow" onClick={scrollToLeft}>
                <div className="icon icon-arrow" />
            </div>

            <div data-disabled={hideRightArrow} className="circle-arrow next-arrow" onClick={scrollToRight}>
                <div className="icon icon-arrow " />
            </div>
        </div>
    </div>
);

const OpenPositionsBlock = () => {
    const isAuthorized = authorized();

    return (
        <section className="section-wrapper block-paddings open-positions-block">
            <Swiper className="swiper-cards" slideWidth={SLIDE_WIDTH} CustomArrows={CustomArrows}>
                {content.map(({ position, location }, index) => (
                    <React.Fragment key={index}>
                        <div draggable={false} className="card slide moved-arrow-wrapper">
                            <div>
                                <h3>{position}</h3>
                                <p className="text">{location}</p>
                            </div>
                            <a draggable={false} href="mailto:recruitment@happytravel.com">
                                <div className="circle-arrow">
                                    <span className="icon icon-arrow" />
                                </div>
                            </a>
                        </div>

                        {index === 1 && (
                            <div draggable={false} className="moved-arrow-wrapper discover-card">
                                <div className="circle-arrow">
                                    <span className="icon icon-arrow" />
                                </div>
                                <Link to={isAuthorized ? '/search' : '/signup'} className="heading">
                                    Discover all opportunities
                                </Link>
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </Swiper>
        </section>
    );
};

export default OpenPositionsBlock;
