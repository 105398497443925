import React from 'react';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { date } from 'simple';

const Deadline = ({ roomContractSet, searchId, htId, loadedDeadline, setLoadedDeadline, checkInDate }) => {
    const deadline = loadedDeadline?.date || roomContractSet.deadline.date;
    const isRequestPossible = !loadedDeadline && !roomContractSet.isNonRefundable;

    const request = (event) => {
        event.stopPropagation();
        API.get({
            url: apiMethods.REQUEST_DEADLINE(searchId, htId, roomContractSet.id),
            success: (data) => {
                setLoadedDeadline(data || {});
            },
        });
    };

    if (isRequestPossible && roomContractSet.deadline?.isFinal !== true)
        return (
            <div onClick={request}>
                Show Cancellation Deadline <i className="icon icon-info" />
            </div>
        );

    if (roomContractSet.isNonRefundable) {
        return (
            <div>
                <span className="non-refundable">Non-Refundable</span>
            </div>
        );
    }

    if (deadline) {
        if (date.isFuture(deadline))
            return (
                <div>
                    <span className="refundable">Refundable </span>
                    Before {date.format.day(deadline, checkInDate)}
                </div>
            );

        return (
            <div>
                <span className="within-deadline">Within Deadline</span>
            </div>
        );
    }

    return null;
};

export default Deadline;
