import * as Yup from 'yup';
import { isPhoneValid } from '../field-phone';

const LETTERS = /^[0-9A-Za-zÀ-ÖØ-öø-ÿ $-)+-/:-?{-~!"^_`\[\]]+$/;

const RESTRICTED_EMAILS = [
    '@aol.com',
    '@att.net',
    '@comcast.net',
    '@facebook',
    '@gmail',
    '@gmx.com',
    '@googlemail.com',
    '@google.com',
    '@hotmail',
    '@hotmail.co.uk',
    '@mac.com',
    '@me.com',
    '@mail.com',
    '@msn',
    '@live.com',
    '@sbcglobal.net',
    '@verizon.net',
    '@email.com',
    '@fastmail.fm',
    '@games.com',
    '@gmx.net',
    '@hush.com',
    '@hushmail.com',
    '@icloud',
    '@iname.com',
    '@inbox.com',
    '@lavabit.com',
    '@love.com',
    '@outlook',
    '@pobox.com',
    '@protonmail.ch',
    '@protonmail.com',
    '@tutanota.de',
    '@tutanota.com',
    '@tutamail.com',
    '@tuta.io',
    '@keemail.me',
    '@rocketmail.com',
    '@safe-mail.net',
    '@wow.com',
    '@ygm.com',
    '@ymail.com',
    '@zoho.com',
    '@yandex',
    '@bellsouth.net',
    '@charter.net',
    '@cox.net',
    '@earthlink.net',
    '@juno.com',
    '@btinternet.com',
    '@virginmedia.com',
    '@blueyonder.co.uk',
    '@freeserve.co.uk',
    '@live.co.uk',
    '@ntlworld.com',
    '@o2.co.uk',
    '@orange.net',
    '@sky.com',
    '@talktalk.co.uk',
    '@tiscali.co.uk',
    '@virgin.net',
    '@wanadoo.co.uk',
    '@bt.com',
    '@sina.com',
    '@sina.cn',
    '@qq.com',
    '@naver.com',
    '@hanmail.net',
    '@daum.net',
    '@nate.com',
    '@yahoo',
    '@163.com',
    '@yeah.net',
    '@126.com',
    '@21cn.com',
    '@aliyun.com',
    '@foxmail.com',
    '@hotmail.fr',
    '@live.fr',
    '@laposte.net',
    '@wanadoo.fr',
    '@orange.fr',
    '@gmx.fr',
    '@sfr.fr',
    '@neuf.fr',
    '@free.fr',
    '@gmx.de',
    '@hotmail.de',
    '@live.de',
    '@online.de',
    '@t-online.de',
    '@web.de',
    '@libero.it',
    '@virgilio.it',
    '@hotmail.it',
    '@aol.it',
    '@tiscali.it',
    '@alice.it',
    '@live.it',
    '@email.it',
    '@tin.it',
    '@poste.it',
    '@teletu.it',
    '@mail.ru',
    '@rambler.ru',
    '@yandex.ru',
    '@ya.ru',
    '@list.ru',
    '@bk.ru',
    '@inbox.ru',
    '@hotmail.be',
    '@live.be',
    '@skynet.be',
    '@voo.be',
    '@tvcablenet.be',
    '@telenet.be',
    '@hotmail.com.ar',
    '@live.com.ar',
    '@fibertel.com.ar',
    '@speedy.com.ar',
    '@arnet.com.ar',
    '@live.com.mx',
    '@hotmail.es',
    '@hotmail.com.mx',
    '@prodigy.net.mx',
    '@hotmail.ca',
    '@bell.net',
    '@shaw.ca',
    '@sympatico.ca',
    '@rogers.com',
    '@hotmail.com.br',
    '@outlook.com.br',
    '@uol.com.br',
    '@bol.com.br',
    '@terra.com.br',
    '@ig.com.br',
    '@itelefonica.com.br',
    '@r7.com',
    '@zipmail.com.br',
    '@globo.com',
    '@globomail.com',
    '@oi.com.br',
];

export default Yup.object().shape({
    firstName: Yup.string()
        .matches(LETTERS, 'Only latin letters allowed')
        .min(2, 'Too short')
        .max(150, 'Too long')
        .required('Required'),
    lastName: Yup.string().matches(LETTERS, 'Only latin letters allowed').max(150, 'Too long'),
    companyName: Yup.string()
        .matches(LETTERS, 'Only latin letters allowed')
        .min(2, 'Too short')
        .max(150, 'Too long')
        .required('Required'),
    companyCity: Yup.string().matches(LETTERS, 'Only latin letters allowed').required('Required'),
    companyCountry: Yup.string().matches(LETTERS, 'Only latin letters allowed').required('Required'),
    phoneNumber: Yup.string()
        .nullable()
        .test('phone', 'Mobile Phone Number is not correct', (phone) => {
            return !phone || isPhoneValid(phone);
        }),
    companyEmail: Yup.string()
        .email('Incorrect email')
        .required('*')
        .test(
            'restricted emails',
            'Only company-registered email addresses are accepted for registration. \nPersonal emails can not be accepted for request, please contact sales@happytravel.com for assistance',
            (email) => !RESTRICTED_EMAILS.some((domain) => email?.includes(domain))
        ),
});
