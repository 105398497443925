import React from 'react';

export const PassengersCount = ({ adults, children }) => {
    let result = [];
    if (adults) result.push(__plural(adults, 'Adult'));
    if (children) result.push(children + (children === 1 ? ' Child' : ' Children'));
    return result.join(', ');
};

export const PassengerName = ({ passenger }) =>
    passenger
        ? (passenger.title ? passenger.title + '. ' : '') + passenger.firstName + ' ' + passenger.lastName
        : '';

export const PassengerNameAndAge = ({ passenger }) =>
    passenger
        ? (typeof passenger.age === 'number' && passenger.age < 18 ? '' : passenger.title + '. ') +
          passenger.firstName +
          ' ' +
          passenger.lastName +
          (typeof passenger.age === 'number' && passenger.age < 18 ? ' (' + passenger.age + ' y.o.)' : '')
        : '';

export const PassengersCountAndAge = ({ rooms }) => {
    return rooms.reduce(
        (acc, item) => {
            acc.adults = acc.adults + (item.adultsNumber || 0);
            acc.childrenAges = [
                ...acc.childrenAges,
                ...(item?.childrenAges || []).map((item) => `${item} y.o.`),
            ];

            return acc;
        },
        { adults: 0, childrenAges: [] }
    );
};
