const WEEKDAYS_LONG = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const WEEKDAYS_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const localeUtils = {
    formatWeekdayShort: (i) => WEEKDAYS_SHORT[i],
    formatWeekdayLong: (i) => WEEKDAYS_LONG[i],
    getMonths: () => MONTHS,
};

function addZero(val) {
    if (val >= 10) return val;
    return '0' + val;
}

const shortMonth = (val) => {
    if (val) {
        return val.substr(0, 3);
    }
};

const dateFormat = (template, rawDate) => {
    let date = new Date(rawDate);
    if (isNaN(date.getTime())) return rawDate;
    return template
        .replace(/DD/g, date.getDate())
        .replace(/dd/g, addZero(date.getDate()))
        .replace(/d/g, date.getDate())
        .replace(/MM/g, addZero(date.getMonth() + 1))
        .replace(/yyyy/g, date.getFullYear())
        .replace(/hh/g, addZero(date.getHours()))
        .replace(/mm/g, addZero(date.getMinutes()))
        .replace(/ss/g, addZero(date.getSeconds()))
        .replace(/DY/g, localeUtils.formatWeekdayShort(date.getDay()))
        .replace(/DAY/g, localeUtils.formatWeekdayLong(date.getDay()))
        .replace(/MTH/g, localeUtils.getMonths()[date.getMonth()])
        .replace(/mth/g, shortMonth(localeUtils.getMonths()[date.getMonth()]));
};

const format = {
    api: (date) => dateFormat('yyyy-MM-ddT00:00:00Z', date),
    apiDateOnly: (date) => dateFormat('yyyy-MM-dd', date),
    apiTime: (date) => dateFormat('yyyy-MM-ddThh:mm:ssZ', date),
    a: (date) => (!date ? '' : dateFormat('DAY, d MTH yyyy', date)),
    c: (date) => (!date ? '' : dateFormat('dd.MM.yyyy', date)),
    g: (date) => (!date ? '' : dateFormat('DY, d mth yyyy', date)),
    dots: (date) => (!date ? '' : dateFormat('dd.MM.yyyy', date)),
    time: (date) => (!date ? '' : dateFormat('hh:mm', date)),
    timeSeconds: (date) => (!date ? '' : dateFormat('hh:mm:ss', date)),
    day: (date) => (!date ? '' : dateFormat('MTH DD', date)),
    shortDay: (date) => (!date ? '' : dateFormat('mth DD', date)),
    month: (date) => (!date ? '' : dateFormat('MTH', date)),
    fullDate: (date) => (!date ? '' : dateFormat('MTH d, yyyy', date)),
    fullFormat: (date) => (!date ? '' : dateFormat('d MTH yyyy', date)),
    deadline: (date, arrival) => {
        if (!date) {
            return '';
        }
        if (!arrival || Math.abs(new Date(arrival) - new Date(date)) > 24 * 60 * 60 * 1000) {
            return dateFormat('DAY, d MTH yyyy', date);
        }
        return dateFormat('DAY, d MTH yyyy, hh:mm', date);
    },
    deadlineShort: (date, arrival) => {
        if (!date) {
            return '';
        }
        if (!arrival || Math.abs(new Date(arrival) - new Date(date)) > 24 * 60 * 60 * 1000) {
            return dateFormat('dd.MM.yyyy', date);
        }
        return dateFormat('dd.MM.yyyy hh:mm', date);
    },
};

const addMonth = (date, amount) => {
    let result = new Date(date),
        previous = result.getMonth();
    result.setMonth(previous + amount);
    return result;
};

const addDay = (date, amount) => {
    let result = new Date(date),
        previous = result.getDate();
    result.setDate(previous + amount);
    return result;
};

const isPast = (date) => {
    if (!date) return true;
    let result = new Date() > new Date(date);
    if (format.api(new Date()) === format.api(date)) result = false;
    return result;
};

const isFuture = (date) => {
    if (!date) return true;
    let result = new Date() < new Date(date);
    if (format.api(new Date()) === format.api(date)) result = false;
    return result;
};

const parseDateRangeFromString = (text = '') => {
    const res = text.split(/[.,\/\- –]/).filter((x) => x);

    if (res.map((v) => v.length).join(',') !== '2,2,4,2,2,4') return null;

    const range = [new Date(+res[2], +res[1] - 1, +res[0]), new Date(+res[5], +res[4] - 1, +res[3])];

    const isDateCorrect = (date) => {
        if (isPast(date)) return false;
        return date.getFullYear() <= new Date().getFullYear() + 5;
    };

    if (!isDateCorrect(range[0]) || !isDateCorrect(range[1])) return null;

    return range;
};

const day = (value) => {
    value = value.substring(0, 10);
    let values = value.split('-');
    return new Date(parseInt(values[0]), parseInt(values[1]) - 1, parseInt(values[2]));
};

export default {
    format,
    addDay,
    addMonth,
    isPast,
    isFuture,
    parseDateRangeFromString,
    day,
};
