import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { searchRoomsCreate } from 'tasks/accommodation/search-rooms-loaders';
import { price } from 'simple';
import { HotelStars } from 'components/accommodation';

const AccommodationSearchResultContract = observer(({ contract }) => {
    const navigate = useNavigate();

    const accommodationSelect = () => {
        searchRoomsCreate(contract, navigate);
    };

    return (
        <div className="contract" onClick={() => accommodationSelect()}>
            <div className={'summary' + __class(contract.isPreferred, 'preferred')}>
                {contract.isPreferred && <div className="plate">Preferred</div>}
                <div
                    className="photo"
                    style={
                        contract.accommodation.photo.sourceUrl
                            ? { backgroundImage: `url("${contract.accommodation.photo.sourceUrl}")` }
                            : null
                    }
                />
                <div className="detail">
                    <div className="upper">
                        <div className="name">
                            <h2>
                                <span>{contract.accommodation.name}</span>
                                <HotelStars count={contract.accommodation.rating} />
                            </h2>
                            <div className="address">{contract.accommodation.location.address}</div>
                        </div>
                    </div>
                    <div className="bottom">
                        <div className="price">
                            <div>From</div>
                            <div className="text-price">{price(contract.currency, contract.minPrice)}</div>
                            {typeof contract.totalMarkupPercent === 'number' ? (
                                <div className="text-markup">
                                    Markup: {contract.totalMarkupPercent.toFixed(2)}%
                                </div>
                            ) : null}
                        </div>
                        <button
                            className="button small availability"
                            onClick={() => accommodationSelect(contract)}
                        >
                            Show Available Options
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default AccommodationSearchResultContract;
