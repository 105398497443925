import React from 'react';
import { Link } from 'react-router-dom';
import { authorized } from 'htcore';

const PartnershipBlock = () => {
    const isAuthorized = authorized();

    const PlatformLink = (
        <a href="#platform" className="info-plate">
            <div className="plate-image-wrapper">
                <img src="/images/landing/product/lrm-small.jpg" alt="lrm" />
                <p>Discover all features</p>
            </div>

            <div className="arrow-wrapper">
                <div className="circle-arrow">
                    <span className="icon icon-arrow" />
                </div>
            </div>
        </a>
    );

    return (
        <section className="section-wrapper">
            <div className="partnership-block">
                <div className="text-wrapper">
                    <h1 className="title">Expansive direct hotel partnerships</h1>
                    <p className="text">
                        <span className="icon icon-plane" />
                        Happy Travel's key strength lies in our extensive network of direct contracts with
                        hotels and chains worldwide.
                    </p>
                    <div className="links-group">
                        {isAuthorized ? (
                            <Link to="/search" className="learn-more-button">
                                <button className="lg-button button-dark">Search Hotels</button>
                            </Link>
                        ) : (
                            <Link to="/signup" className="learn-more-button">
                                <button className="lg-button button-dark">Discover Our Platform</button>
                            </Link>
                        )}

                        <a href="#platform" className="dark-link animated-border">
                            Read more
                        </a>
                    </div>

                    <div className="platform-link hide-laptop">{PlatformLink}</div>
                </div>

                <img
                    className="application-image"
                    src="/images/landing/product/application.png"
                    alt="Product application"
                />
                <div className="platform-link hide-laptop-desktop">{PlatformLink}</div>
            </div>
        </section>
    );
};

export default PartnershipBlock;
