import React, { useState } from 'react';
import { observer } from 'mobx-react';
import EditSpecialRequestModal from '../confirmation/edit-special-request-modal';
import { $notifications } from 'stores';

const BookingSpecialRequestPart = observer(({ isService, booking, loadBooking }) => {
    const [isModalOpen, setModalOpen] = useState(false);

    if (isService || !booking?.isSpecialRequestsSupported) {
        return null;
    }

    return (
        <div className="actions">
            <EditSpecialRequestModal
                booking={booking}
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onSuccess={() => {
                    $notifications.addNotification('Special Request Updated!', null, 'success');
                    loadBooking();
                    setModalOpen(false);
                }}
            />
            <button className="button main" onClick={() => setModalOpen(true)}>
                {booking.specialRequest ? 'Update Special Request to Hotel' : 'Add Special Request to Hotel'}
            </button>
        </div>
    );
});

export default BookingSpecialRequestPart;
