import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useTitle } from 'htcore';
import BlogMainPage from './blog-main-page';
import BlogArticle from './blog-article';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Consent from '../../components/consent';
import useGoogleAnalytics from '../../hooks/use-google-analytics';

const BlogPage = () => {
    useTitle('Blog');
    useGoogleAnalytics();

    return (
        <div className="lg">
            <div className="container">
                <Header />

                <Routes>
                    <Route path="/" element={<BlogMainPage />} />
                    <Route path="/:id" element={<BlogArticle />} />
                </Routes>
            </div>

            <Consent />
            <Footer />
        </div>
    );
};

export default BlogPage;
