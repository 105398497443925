import React from 'react';
import { Link } from 'react-router-dom';
import { authorized } from '../../../htcore';

const TailorMadeBlock = () => {
    const isAuthorized = authorized();

    return (
        <section className="section-wrapper tailor-made-block">
            <div className="content">
                <h2>Tailor-made trips and direct hotel bargains await</h2>
                <p className="text">
                    Secure tailored travel itineraries and exclusive hotel offers for your clients
                </p>

                {isAuthorized ? (
                    <a href="mailto:reservations@happytravel.com" className="learn-more-button">
                        <button className="lg-button button-secondary">Request contact</button>
                    </a>
                ) : (
                    <Link to="/signup" className="learn-more-button">
                        <button className="lg-button button-dark">Request Contact</button>
                    </Link>
                )}
            </div>
        </section>
    );
};

export default TailorMadeBlock;
