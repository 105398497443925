import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { authorized } from 'htcore';

const OurStoryBlock = () => {
    const counterRef = useRef(null);
    const isAuthorized = authorized();

    useEffect(() => {
        const element = counterRef.current;
        element.style.setProperty('--num', 98);
    }, []);

    return (
        <section className="section-wrapper">
            <div className="our-story-block">
                <div>
                    <h1 className="title">The story behind Happy Travel</h1>
                    <p className="text">
                        <span className="icon icon-plane" />
                        Every itinerary is a chapter in our story of connecting worlds and creating
                        possibilities. Born from a passion for exploration, we've been your reliable navigator
                        in the travel industry, crafting bespoke journeys with precision and care.
                    </p>
                    <Link to="/product" className="learn-more-button">
                        <button className="lg-button button-dark">Learn more about us</button>
                    </Link>

                    <a href="#join-us">
                        <div className="info-plate">
                            <div>
                                <h3 className="counter" ref={counterRef} />
                                <p>languages office fluency</p>
                            </div>

                            <div className="arrow-wrapper">
                                <div className="circle-arrow move-up-down-arrow">
                                    <span className="icon icon-arrow" />
                                </div>
                            </div>
                        </div>
                    </a>
                </div>

                <div className="images-content">
                    <div className="image-wrapper main-image">
                        <img src="/images/landing/about-us/meeting.jpg" alt="Meeting" />
                    </div>

                    <div className="product-block-wrapper">
                        <Link to={isAuthorized ? '/search' : '/signup'} className="product-block">
                            <img src="/images/landing/about-us/work-process.jpg" alt="Team work" />
                            <div className="inside-info moved-arrow-wrapper">
                                <div className="circle-arrow">
                                    <span className="icon icon-arrow" />
                                </div>
                                <p className="heading">Discover our product in details</p>
                            </div>
                        </Link>

                        <a href="mailto:recruitment@happytravel.com" className="join-block">
                            <div className="inside-info moved-arrow-wrapper">
                                <div className="circle-arrow">
                                    <span className="icon icon-arrow" />
                                </div>

                                <h3>Join our team</h3>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurStoryBlock;
