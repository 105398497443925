import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Swiper from '../../components/swiper';

const SLIDE_WIDTH = 450;

const OpinionBlock = () => {
    const containerRef = useRef(null);

    const revenueCounterRef = useRef(null);
    const bookingsCounterRef = useRef(null);
    const partnersCounterRef = useRef(null);

    const observerCallback = (entries) => {
        const [entry] = entries;

        if (entry.isIntersecting) {
            revenueCounterRef.current.style.setProperty('--revenue', 47);
            bookingsCounterRef.current.style.setProperty('--bookings', 30);
            partnersCounterRef.current.style.setProperty('--partners', 50);
        }
    };

    const options = { root: null, rootMargin: '300px', threshold: 1.0 };

    useEffect(() => {
        const observer = new IntersectionObserver(observerCallback, options);
        if (containerRef.current) observer.observe(containerRef.current);

        return () => (containerRef.current ? observer.unobserve(containerRef.current) : null);
    }, [containerRef, options]);

    return (
        <section ref={containerRef} className="section-wrapper block-paddings opinion-block">
            <h2>
                See what others <span>say about us</span>
            </h2>

            <Swiper className="swiper-cards" slideWidth={SLIDE_WIDTH}>
                <div draggable={false} className="card slide">
                    <p className="card-heading">
                        “Lovely experience booking our staycation through Happy Travel. Our consultant Juliet
                        Thomas took care of everything and the booking experience was hassle free. Our stay
                        was booked with Adnaaz the Palm. The property was cosy and comfortable.”
                    </p>
                    <p className="small-text">— Rukhsar Shaikh</p>
                </div>

                <div draggable={false} className="discover-card moved-arrow-wrapper">
                    <div className="circle-arrow">
                        <span className="icon icon-arrow" />
                    </div>
                    <Link to="/about-us" className="heading">
                        Learn more about us
                    </Link>
                </div>

                <div draggable={false} className="card slide">
                    <div className="counters-wrapper">
                        <div>
                            <h3>
                                <span ref={revenueCounterRef} className="revenue-number" />%
                            </h3>
                            <p className="text">revenue increase year over year</p>
                        </div>
                        <div>
                            <h3>
                                <span ref={bookingsCounterRef} className="bookings-number" />
                                ,000
                            </h3>
                            <p className="text">bookings per month</p>
                        </div>
                        <div>
                            <h3>
                                > <span ref={partnersCounterRef} className="partners-number" />
                            </h3>
                            <p className="text">technology partners</p>
                        </div>
                    </div>
                </div>

                <div draggable={false} className="card slide">
                    <p className="small-text">— Rukhsar Shaikh</p>
                    <p className="card-heading">
                        “Lovely experience booking our staycation through Happy Travel. Our consultant Juliet
                        Thomas took care of everything and the booking experience was hassle free.”
                    </p>
                </div>
            </Swiper>
        </section>
    );
};

export default OpinionBlock;
