import React from 'react';
import { Link } from 'react-router-dom';
import content from '../../content/blog.json';

const BlogMainPage = () => (
    <section className="section-wrapper blog-lg">
        <h1>Blog</h1>

        <div className="content">
            {content.map((article) => (
                <Link key={article.id} to={`./${article.id}`}>
                    <img className="image" src={article.photo} alt={article.title} />
                    <h3 className="title">{article.title}</h3>
                    <p className="text">{article.description}</p>
                </Link>
            ))}
        </div>
    </section>
);

export default BlogMainPage;
