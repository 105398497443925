import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import AgentMenu from './agent-menu';
import Search from 'pages/accommodation/search-form/search-form';
const Header = () => {
    const location = useLocation();
    const withSearch =
        location.pathname.includes('/search/results') || location.pathname.includes('/search/contract');

    return (
        <header className={withSearch ? 'with-search' : null}>
            <section>
                <div className="logo-wrapper">
                    <div className="logo">
                        <Link to="/search" className="image" />
                        <div className="underlined-text" />
                    </div>
                </div>
                {withSearch && (
                    <div className="search-wrapper">
                        <Search key="search-short" />
                    </div>
                )}
                <AgentMenu />
            </section>
        </header>
    );
};

export default Header;
