import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import RoomSummary from '../parts/room-summary';
import { $accommodation } from 'stores';

const RoomContractSetGroup = observer(({ group, groupsCount, roomContractSetSelect }) => {
    const [isExpanded, setExpansion] = useState(true);
    const selectedAccommodation = $accommodation.selected.accommodation;

    const toggle = useCallback(() => setExpansion(!isExpanded), [setExpansion, isExpanded]);

    return (
        <div className="group">
            {groupsCount > 1 ? (
                <div className="name" onClick={toggle}>
                    {group.groupName || 'Other Room Categories'} ({group.roomContractSets?.length || 0})
                    <span className={'icon icon-big-expand' + (isExpanded ? ' expanded' : '')} />
                </div>
            ) : null}
            {isExpanded
                ? group.roomContractSets?.map((roomContractSet, index) => (
                      <RoomSummary
                          key={index}
                          roomContractSet={roomContractSet}
                          onSelect={() => roomContractSetSelect(roomContractSet)}
                          htId={selectedAccommodation.htId}
                          accommodationName={selectedAccommodation.name}
                      />
                  ))
                : null}
        </div>
    );
});

export default RoomContractSetGroup;
