import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { GroupRoomTypesAndCount, price, date } from 'simple';
import { copyToClipboard } from 'simple/logic';
import { MealPlan } from 'components/accommodation';
import Deadline from 'components/deadline';
import { DailyRatesContract } from 'components/simple';
import { $accommodation } from 'stores';

const RoomSummary = observer(({ htId, roomContractSet, onSelect, accommodationName }) => {
    const [loadedDeadline, setLoadedDeadline] = useState(null);
    const [isDailyRatesVisible, setDailyRatesVisibility] = useState(null);

    const toggleDailyRates = useCallback(() => setDailyRatesVisibility((v) => !v), [setDailyRatesVisibility]);

    const copy = (e) => {
        e.stopPropagation();

        const deadline = loadedDeadline?.date || roomContractSet.deadline.date;
        let deadlineString = '';

        if (roomContractSet.deadline.isFinal || loadedDeadline) {
            if (!deadline) deadlineString = 'FREE Cancellation - Without Prepayment';
            else if (date.isFuture(deadline)) deadlineString = 'Deadline' + ': ' + date.format.dots(deadline);
            else deadlineString = 'Within deadline';
            deadlineString += ', ';
        }

        copyToClipboard(
            [
                date.format.dots(date.day($accommodation.search.request.checkInDate)) +
                    ' – ' +
                    date.format.dots(date.day($accommodation.search.request.checkOutDate)),
                accommodationName,
                GroupRoomTypesAndCount({ contracts: roomContractSet.rooms, text: true }),
                $accommodation.search.request.adultsTotal +
                    'A' +
                    ($accommodation.search.request.childrenTotal
                        ? ', ' + $accommodation.search.request.childrenTotal + 'C'
                        : ''),
                roomContractSet.rooms[0].mealPlan,
                deadlineString + price(roomContractSet.rate.finalPrice).trim(),
            ].join(', ')
        );
    };

    const showDailyRates = Boolean(roomContractSet.rooms[0]?.dailyRoomRates?.length);

    const FinalMealPlan = roomContractSet.rooms[0]?.boardBasis ? (
        <MealPlan short room={roomContractSet.rooms[0]} allRooms={roomContractSet.rooms} />
    ) : (
        'Room Only'
    );

    const FinalDeadline = (
        <Deadline
            searchId={$accommodation.search.id}
            htId={htId}
            roomContractSet={roomContractSet}
            loadedDeadline={loadedDeadline}
            setLoadedDeadline={setLoadedDeadline}
            checkInDate={$accommodation.search.request.checkInDate}
        />
    );

    const Tags = (
        <>
            {roomContractSet.supplier && (
                <div className="tag">
                    Supplier:{' '}
                    {roomContractSet.supplier +
                        (roomContractSet.subSupplier ? ` (${roomContractSet.subSupplier})` : '')}
                </div>
            )}
            <div className="tag deadline-tag show-phone">{FinalDeadline}</div>
            <div className="tag show-phone">Meal Plan: {FinalMealPlan}</div>
            {Boolean(roomContractSet.rooms.find((item) => item.isAvailableImmediately === false)) && (
                <div className="tag">
                    <b>On Request</b>
                </div>
            )}
            {roomContractSet.isDirectContract && <div className="tag">Direct Connectivity</div>}
            {roomContractSet.isPackageRate && <div className="tag">Package Rate</div>}
            {roomContractSet.rooms[0]?.isDynamic === true && <div className="tag">Dynamic Offer</div>}
        </>
    );

    return (
        <>
            <div className="room">
                <div className="room-details">
                    <div className="info" onClick={onSelect}>
                        <h3 className="copier-wrapper">
                            <div>
                                <GroupRoomTypesAndCount contracts={roomContractSet.rooms} />
                            </div>
                            <span onClick={copy} className="copier" title="Copy to Clipboard">
                                <span className="icon icon-copy" />
                            </span>
                        </h3>
                        <div className="hide-phone">{Tags}</div>
                    </div>
                    <div className="deadline-column" onClick={onSelect}>
                        <div className="label">Deadline</div>
                        {FinalDeadline}
                    </div>
                    <div className="meal-plan" onClick={onSelect}>
                        <div className="label">Meal Plan</div>
                        {FinalMealPlan}
                    </div>
                    <div className="price" onClick={showDailyRates ? toggleDailyRates : onSelect}>
                        {price(roomContractSet.rate.finalPrice)}
                        {roomContractSet.rate.totalMarkupPercent !== null ? (
                            <div className="note">
                                Markup: {roomContractSet.rate.totalMarkupPercent?.toFixed(2)}%
                            </div>
                        ) : null}
                        {showDailyRates ? (
                            <div className="note daily-rates-button-holder">
                                <button>
                                    Show Daily Rates
                                    <span
                                        className={
                                            'icon icon-big-expand' + (isDailyRatesVisible ? ' expanded' : '')
                                        }
                                    />
                                </button>
                            </div>
                        ) : null}
                    </div>
                    <div className="button-holder">
                        <div className="button-wrapper">
                            <button className="button small main" onClick={onSelect}>
                                <i className="icon icon-arrow-next" />
                            </button>
                        </div>
                    </div>
                </div>
                {isDailyRatesVisible && <DailyRatesContract rooms={roomContractSet.rooms} />}
                <div className="phone-tags">{Tags}</div>
            </div>
        </>
    );
});

export default RoomSummary;
