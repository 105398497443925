import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import OidcInstance from 'htcore/auth/oidc-instance';
import Breadcrumbs from 'components/navigation/breadcrumbs';
import { CachedForm, FORM_NAMES, FieldText, FieldPhone } from 'components/form';
import { registrationCompanyValidator } from 'components/form/validation';

const SignUpCompanyForm = ({ submit }) => {
    const [captcha, setCaptcha] = useState(null);

    const auth = () => {
        OidcInstance().signinRedirect();
    };

    return (
        <div>
            <Breadcrumbs
                items={[
                    {
                        text: 'Happytravel.com',
                        link: '/search',
                    },
                    {
                        text: 'Join Us',
                    },
                ]}
                noBackButton
            />
            <h2>Become a partner of Happytravel.com</h2>
            <div className="paragraph">
                You will be able to use our amazing system with access to the best rates for thousands of
                luxury properties all over the world with just a click!
            </div>
            <div className="paragraph">
                Create an account and start booking with Happytravel.com. Our managers will contact you
                shortly.
            </div>
            <div className="paragraph">
                Already have an account?{' '}
                <button className="link" onClick={auth}>
                    Sign In
                </button>
            </div>

            <CachedForm
                id={FORM_NAMES.RegistrationCompanyForm}
                validationSchema={registrationCompanyValidator}
                onSubmit={(values) => submit(values, captcha)}
                initialValues={{ nonTRN: false }}
                render={(formik) => (
                    <div className="form">
                        <div className="row">
                            <FieldText
                                formik={formik}
                                id="firstName"
                                label="First Name"
                                placeholder="First Name"
                                className="size-half"
                                required
                            />
                            <FieldText
                                formik={formik}
                                id="lastName"
                                label="Last Name"
                                placeholder="Last Name"
                                className="size-half"
                            />
                        </div>
                        <div className="row">
                            <FieldText
                                formik={formik}
                                id="companyName"
                                label="Company Name"
                                placeholder="Company Name"
                                required
                            />
                        </div>
                        <div className="row">
                            <FieldText
                                formik={formik}
                                id="companyCity"
                                label="Company City"
                                placeholder="City"
                                className="size-half"
                                required
                            />
                            <FieldText
                                formik={formik}
                                id="companyCountry"
                                label="Company Country"
                                placeholder="Country"
                                className="size-half"
                                required
                            />
                        </div>
                        <div className="row">
                            <FieldPhone
                                formik={formik}
                                id="phoneNumber"
                                label="Phone Number"
                                placeholder="Phone Number"
                                numeric
                                required
                            />
                        </div>
                        <div className="row" style={{ whiteSpace: 'pre-wrap' }}>
                            <FieldText
                                formik={formik}
                                id="companyEmail"
                                label="Company Email"
                                placeholder="Company Email"
                                required
                            />
                        </div>
                        <div className="row">
                            <FieldText formik={formik} id="website" label="Website" placeholder="Website" />
                        </div>
                        <ReCAPTCHA
                            sitekey="6Ldl9xgqAAAAAHB-YtzAu_TP1GqwanEa8Syq2QSc"
                            onChange={(val) => setCaptcha(val)}
                            onExpired={() => setCaptcha(null)}
                        />
                        <div className="row">
                            <div className="field">
                                <div className="inner">
                                    <button
                                        type="submit"
                                        className={
                                            'main button' + __class(!formik.isValid || !captcha, 'disabled')
                                        }
                                        disabled={!formik.isValid || !captcha}
                                    >
                                        Send Request
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="paragraph" style={{ paddingLeft: 0 }}>
                            By clicking this button, you agree with{' '}
                            <a href="/terms" className="link" target="_blank">
                                HappyTravel’s Terms of Use
                            </a>
                        </div>
                    </div>
                )}
            />
        </div>
    );
};

export default SignUpCompanyForm;
