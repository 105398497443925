import React from 'react';
import { Link } from 'react-router-dom';
import Swiper from '../../components/swiper';
import TextBlock from '../../components/text-block';

const TechnologyBlock = () => (
    <section id="technology" className="section-wrapper block-paddings home-technology-block">
        <TextBlock
            title={
                <>
                    Technology as your ally for <span>custom-made</span> journeys
                </>
            }
            text="Our smart tools assist you in handpicking and crafting the perfect travel experiences
                        for every client, ensuring each trip is as unique and memorable as the destinations
                        they visit."
        >
            <Link to="/product" className="link-button">
                <button className="lg-button button-secondary">Discover Our Platform</button>
            </Link>
        </TextBlock>

        <Swiper className="swiper-cards">
            <div className="card slide">
                <div>
                    <h3>Optimized for on-the-go access</h3>
                    <p className="text">
                        Built for the fast-paced travel industry, our platform offers quick access to
                        essential tools and the best B2B rates through a clean, user-friendly interface.
                    </p>
                    <Link to="/product" className="dark-link animated-border">
                        Learn more
                    </Link>
                </div>

                <div className="phone-img-wrapper">
                    <img
                        draggable="false"
                        className="phone-img"
                        src="/images/landing/home/phone.png"
                        alt="phone"
                    />
                </div>
            </div>

            <div className="card slide">
                <h3>Personal account manager</h3>
                <p className="text">
                    Every travel agency that books with us is assigned their own account manager. This ensures
                    personalized service and support tailored to each agency's unique needs.
                </p>
                <Link to="/product" className="dark-link animated-border">
                    Learn more
                </Link>
            </div>

            <div className="card slide">
                <h3>Concierge services</h3>
                <p className="text">
                    Discover our extensive concierge services available in many destinations. Reach out to
                    your account manager today to learn about the additional services we offer at various
                    locations.
                </p>
                <Link to="/product" className="dark-link animated-border">
                    Learn more
                </Link>
            </div>
        </Swiper>
    </section>
);

export default TechnologyBlock;
