import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { $ui } from 'stores';
import { authorized } from 'htcore';
import FooterBanner from './footer-banner';

const Footer = observer(() => {
    const HappyTravelDotCom = $ui.ourCompanyInfo;
    const isAuthorized = authorized();

    return (
        <footer className="lg lg-footer">
            <div className="container">
                <section className="section-wrapper">
                    <img className="logo hide-desktop" src="/images/landing/logo.png" alt="logo" />
                    <div className="links-wrapper">
                        <div className="links-group">
                            <Link to="/" className="dark-link">
                                Home
                            </Link>
                            <Link to="/about-us" className="dark-link">
                                About us
                            </Link>
                            <Link to="/product" className="dark-link">
                                Our Product
                            </Link>
                            <Link to="/blog" className="dark-link hide-mobile">
                                Blog
                            </Link>
                            {isAuthorized ? (
                                <Link to="/search" className="dark-link">
                                    Search Accommodation
                                </Link>
                            ) : (
                                <Link to="/signup" className="dark-link">
                                    Join Us
                                </Link>
                            )}
                        </div>
                        <FooterBanner />
                        <div className="links-group">
                            <span className="hide-laptop">
                                © 2024, HappyTravelDotCom Travel and Tourism LLC
                            </span>
                            <div className="links-group">
                                <Link to="/blog" className="dark-link hide-desktop">
                                    Blog
                                </Link>
                                <Link to="/terms" className="dark-link">
                                    Terms &amp; conditions
                                </Link>
                                <Link to="/privacy" className="dark-link">
                                    Privacy Policy
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="info-wrapper">
                        <div className="info">
                            <div>
                                <p>
                                    Address:{' '}
                                    <span>
                                        {HappyTravelDotCom.name} {HappyTravelDotCom.address} P.O.{' '}
                                        {HappyTravelDotCom.postalCode} {HappyTravelDotCom.city},{' '}
                                        {HappyTravelDotCom.country}
                                    </span>
                                </p>
                            </div>

                            <div>
                                <p>
                                    Email:{' '}
                                    <a
                                        className="underlined-text contact-link"
                                        href={`mailto:${HappyTravelDotCom.email}`}
                                    >
                                        {HappyTravelDotCom.email}
                                    </a>
                                </p>
                                <p>
                                    Recruitment Email:{' '}
                                    <a
                                        className="underlined-text contact-link"
                                        href="mailto:recruitment@happytravel.com"
                                    >
                                        recruitment@happytravel.com
                                    </a>
                                </p>
                            </div>
                            <div>
                                <p>
                                    Phone:{' '}
                                    <a className="underlined-text contact-link" href="tel:+971-4-2940007">
                                        {HappyTravelDotCom.phone}
                                    </a>
                                </p>
                                <p>
                                    TRN: <span>{HappyTravelDotCom.trn}</span>
                                </p>
                                <p>
                                    Trade License: <span>{HappyTravelDotCom.tradeLicense}</span>
                                </p>
                            </div>

                            <div className="icons-group">
                                <span className="icon icon-mastercard" />
                                <span className="icon icon-mastercard-id" />
                                <span className="icon icon-visa" />
                                <span className="icon icon-visa-secure" />
                                <span className="icon icon-amex" />
                            </div>
                        </div>
                        <span className="hide-laptop-desktop">© 2024, {HappyTravelDotCom.name}</span>
                    </div>
                </section>
            </div>
        </footer>
    );
});

export default Footer;
