import React, { useEffect, useRef, useState } from 'react';
import { authorized } from 'htcore';
import { $accommodation } from 'stores';
import { OidcInstance } from 'htcore/auth';
import { SEARCH_STATUSES } from 'enum';
import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';
import settings from 'settings';
import { subscribeSearchResults, getSearchState } from './search-loaders';

export const useSearchSocketConnection = () => {
    const [token, setToken] = useState(null);
    const connectedTokenRef = useRef();

    useEffect(() => {
        if (!authorized()) return;
        OidcInstance()
            .getUser()
            .then((user) => user?.access_token && setToken(user.access_token));
    }, [authorized()]);

    useEffect(() => {
        if (!token) return;

        let throttle = setTimeout(() => {
            if (window.searchSocketConnected) {
                getSearchState();
            }
        }, 3000);

        if (window.searchConnection && window.searchSocketConnected && connectedTokenRef.current === token) {
            return;
        }

        try {
            connectedTokenRef.current = token;
            window.searchConnection = new HubConnectionBuilder()
                .withUrl(settings.edo + `signalr/search`, {
                    accessTokenFactory: () => token,
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets,
                })
                .build();

            window.searchConnection.on('SearchStateChanged', ({ id, taskState, resultCount, error }) => {
                try {
                    if (
                        id &&
                        id === $accommodation.search.id && // todo: is id here stored correctly?
                        (resultCount || SEARCH_STATUSES.isFinished(taskState) || error)
                    ) {
                        clearTimeout(throttle);
                        throttle = setTimeout(() => {
                            getSearchState(true, id);
                        }, 1000);
                    }
                } catch (e) {
                    window.searchSocketConnected = false;
                }
            });

            window.searchConnection.start().catch(() => {
                window.searchSocketConnected = false;
            });

            window.searchSocketConnected = true;
        } catch (e) {}
    }, [token, $accommodation.search.id]);

    return null;
};

export const useSearchIntervalUpdater = () => {
    useEffect(() => {
        const interval = subscribeSearchResults();
        return () => {
            clearInterval(interval);
        };
    }, []);

    return null;
};
