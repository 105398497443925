import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { authorized } from 'htcore';
import content from '../../content/questions.json';

const QuestionsBlock = () => {
    const [openedQuestion, setOpenedQuestion] = useState();

    const isAuthorized = authorized();

    const onIconClick = (question) =>
        setOpenedQuestion((prevState) => (prevState === question ? null : question));

    return (
        <section className="section-wrapper block-paddings questions-block">
            <h2>Have questions?</h2>
            <div className="content" data-auth={!!isAuthorized}>
                <div className="questions-list">
                    {content.map(({ isNotShowForAuth, question, answer }) => {
                        if (isAuthorized && isNotShowForAuth) return null;

                        return (
                            <div
                                key={question}
                                onClick={() => onIconClick(question)}
                                className="question-card"
                                data-opened={openedQuestion === question}
                            >
                                <div className="question">
                                    <p>{question}</p>
                                    <div className=" icon-wrapper">
                                        <span className="icon icon-minus" />
                                        <span className="icon icon-minus icon-plus" />
                                    </div>
                                </div>
                                <p className="text">{answer}</p>
                            </div>
                        );
                    })}
                </div>
                {!isAuthorized && (
                    <div className="image-block">
                        <div className="image-block-content">
                            <h3>Start on your path to B2B success</h3>
                            <Link to="/signup">
                                <button className="lg-button button-primary">Request contact</button>
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default QuestionsBlock;
